import React from 'react'
import PropTypes from 'prop-types'
import { configuratorFormPropType } from 'lib/prop-types'
import { withPrefix } from 'gatsby'
import getIconName from 'lib/get-icon-name'
import { ReactSVG } from 'react-svg'

const kWh = {
  value: 'kWhElectricity',
  label: 'kWh Strom',
}

const units = {
  oil: [
    { value: 'heatingOilInLiter', label: 'Liter Heizöl' },
    { value: 'heatingOilInKg', label: 'kg Heizöl' },
  ],
  gas: [
    { value: 'gasInM3', label: 'm3 Gas' },
    { value: 'gasInKWh', label: 'kWh Gas' },
  ],
  wood: [
    {
      value: 'woodChipsInKgConiferousWood',
      label: 'kg Holzschnitzel (Nadelholz)',
    },
    {
      value: 'woodChipsInKgBeechOak',
      label: 'kg Holzschnitzel (Buche/Eiche)',
    },
    {
      value: 'woodChipsInM3ConiferousWood',
      label: 'm3 Holzschnitzel (Nadelholz)',
    },
    {
      value: 'woodChipsInM3BeechOak',
      label: 'm3 Holzschnitzel (Buche/Eiche)',
    },
    { value: 'pelletsInKg', label: 'kg Pellets' },
    { value: 'pelletsInM3', label: 'm3 Pellets' },
    {
      value: 'sterePieceWoodConiferousWood',
      label: 'Ster Stückholz (Nadelholz)',
    },
    {
      value: 'sterePieceWoodBeechOak',
      label: 'Ster Stückholz (Buche/Eiche)',
    },
    { value: 'sterePieceWoodMix', label: 'Ster Stückholz (Mix)' },
  ],
  electricalStorage: [kWh],
  airOrWaterHeatPump: [kWh],
  geothermalProbeHeatPump: [kWh],
}

export const CurrentTypeOfHeating = props => {
  const options = [
    { value: 'oil', label: 'Öl' },
    { value: 'gas', label: 'Erdgas' },
    { value: 'wood', label: 'Holz' },
    { value: 'electricalStorage', label: 'Elektrospeicher' },
    {
      value: 'airOrWaterHeatPump',
      label: 'Luft/Wasser Wärmepumpen',
    },
    {
      value: 'geothermalProbeHeatPump',
      label: 'Erdsonden Wärmepumpen',
    },
  ]

  return (
    <div className="config__step">
      <h2 className="config__step--title">Womit heizen Sie bisher?</h2>
      <div className="config__items">
        {options.map(({ value, label }) => {
          const key = 'currentTypeOfHeating'
          const id = `${key}-option-${value}`
          const isChecked = props.configuration[key].value === value
          return (
            <div
              key={id}
              className={`config__item ${isChecked ? 'active' : ''}`}
            >
              <input
                onChange={() => {
                  const keys = [key]
                  const values = [{ value, label }]
                  if (value) {
                    keys.push('energyConsumptionUnits')
                    values.push(units[value][0])
                  }
                  props.handleSelectChange(keys, values)
                  props.nextStep()
                }}
                type="radio"
                name={key}
                id={id}
                className="dn"
                value={value}
                checked={isChecked}
              />
              <label htmlFor={id} className="config__item--content">
                <ReactSVG
                  src={withPrefix(`/icons/${getIconName(key, label)}.svg`)}
                  className="config__item--icon"
                  wrapper="span"
                />

                <div className="config__item--title">{label}</div>
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export class DeclaredEnergyConsumption extends React.Component {
  focusInput() {
    setTimeout(() => {
      if (this.props.isActive && this.input) {
        this.input.focus()
      }
    }, 500)
  }
  componentDidMount() {
    this.focusInput()
  }
  componentDidUpdate(prevProps) {
    if (this.props.isActive && !prevProps.isActive) {
      this.focusInput()
    }
  }
  render() {
    const possibleUnits =
      units[this.props.configuration.currentTypeOfHeating.value]
    let unitSelect = null
    if (possibleUnits) {
      unitSelect = possibleUnits.map(({ value, label }) => {
        const key = 'energyConsumptionUnits'
        const id = `${key}-option-${value}`
        const isChecked = this.props.configuration[key].value === value
        return (
          <div key={id} className={`config__item ${isChecked ? 'active' : ''}`}>
            <input
              onChange={() => {
                this.props.handleSelectChange(key, { value, label })
                if (this.input.value !== '') {
                  this.props.nextStep()
                }
              }}
              type="radio"
              name={key}
              id={id}
              className="dn"
              value={value}
              checked={isChecked}
            />
            <label htmlFor={id} className="config__item--content">
              <ReactSVG
                src={withPrefix(`/icons/${getIconName(key, label)}.svg`)}
                className="config__item--icon"
                wrapper="span"
              />

              <div className="config__item--title">{label}</div>
            </label>
          </div>
        )
      })
    }
    return (
      <div className="config__step">
        <h2 className="config__step--title">Wie hoch ist der Verbrauch?</h2>
        <div className="config__item--input">
          <input
            onChange={e => {
              this.props.handleChange(e)
            }}
            onFocus={() => {
              this.props.displayPlaceholder({ display: true })
            }}
            onBlur={() => {
              this.props.displayPlaceholder({ display: false })
            }}
            type="number"
            name="declaredEnergyConsumption"
            id="declaredEnergyConsumption"
            value={this.props.configuration.declaredEnergyConsumption}
            placeholder="Bitte hier Menge eingeben"
            ref={input => (this.input = input)}
          />
        </div>
        <div className="config__items">{unitSelect}</div>
      </div>
    )
  }
}
export const HeatDistributionSystem = props => {
  const options = [
    { value: 'floorHeating', label: 'Bodenheizung' },
    { value: 'radiators', label: 'Radiatoren' },
    {
      value: 'floorHeatingAndRadiators',
      label: 'Bodenheizung und Radiatoren',
    },
    { value: 'otherDistributionType', label: 'Anderes Verteilsystem' },
  ]
  return (
    <div className="config__step">
      <h2 className="config__step--title">Wie werden Ihre Räume beheizt?</h2>
      <div className="config__items">
        {options.map(({ value, label }) => {
          const key = 'heatDistributionSystem'
          const id = `${key}-option-${value}`
          const isChecked = props.configuration[key].label === label

          return (
            <div
              key={id}
              className={`config__item ${isChecked ? 'active' : ''}`}
            >
              <input
                onChange={() => {
                  props.handleSelectChange(key, { value, label })
                  props.nextStep()
                }}
                type="checkbox"
                name={key}
                id={id}
                className="dn"
                value={value}
                checked={isChecked}
              />
              <label htmlFor={id} className="config__item--content">
                <ReactSVG
                  src={withPrefix(`/icons/${getIconName(key, label)}.svg`)}
                  className="config__item--icon"
                  wrapper="span"
                />

                <div className="config__item--title">{label}</div>
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export const HeatedArea = props => {
  const options = [75, 100, 125, 150, 175, 200]
  const key = 'heatedArea'
  return (
    <div className="config__step">
      <h2 className="config__step--title">
        Wie groß ist die beheizte Wohnfläche?
      </h2>
      <div className="config__items">
        {options.map(value => {
          const label = value.toString()
          const id = `${key}-option-${value}`
          const isChecked = props.configuration[key].value === value
          return (
            <div
              key={id}
              className={`config__item ${isChecked ? 'active' : ''}`}
            >
              <input
                onChange={() => {
                  props.handleSelectChange(key, { value, label })
                  props.nextStep()
                }}
                type="radio"
                name={key}
                id={id}
                className="dn"
                value={value}
                checked={isChecked}
              />
              <label htmlFor={id} className="config__item--content">
                <div className="config__item--title">
                  {label}m<sup>2</sup>
                </div>
              </label>
            </div>
          )
        })}
      </div>
      <div className="config__item--input">
        <input
          onChange={e => {
            const value = e.target.value
            props.handleSelectChange(key, {
              value: value,
              label: value.toString(),
            })
          }}
          type="number"
          name={key}
          id={key}
          value={props.configuration[key].value}
          placeholder="Wohnfläche eingeben"
        />
        <span className="margin--l05 color--secondary">
          <strong>
            m<sup>2</sup>
          </strong>
        </span>
      </div>
    </div>
  )
}
export const WaterHeating = props => {
  const options = [
    { value: 'electricWaterHeater', label: 'Elektroboiler' },
    { value: 'radiator', label: 'Mit der Heizung' },
    { value: 'heatPumpWaterHeater', label: 'Wärmepumpen-Boiler' },
    { value: 'solarThermalEnergy', label: 'Solarthermie' },
    { value: 'other', label: 'Ohne' },
  ]

  return (
    <div className="config__step">
      <h2 className="config__step--title">
        Wie wird bei Ihnen das Wasser erwärmt?
      </h2>
      <div className="config__items">
        {options.map(({ value, label }) => {
          const key = 'waterHeating'
          const id = `${key}-option-${value}`
          const isChecked = props.configuration[key].value === value
          return (
            <div
              key={id}
              className={`config__item ${isChecked ? 'active' : ''}`}
            >
              <input
                onChange={() => {
                  props.handleSelectChange(key, { value, label })
                  props.nextStep()
                }}
                type="radio"
                name={key}
                id={id}
                className="dn"
                value={value}
                checked={isChecked}
              />
              <label htmlFor={id} className="config__item--content">
                <ReactSVG
                  src={withPrefix(`/icons/${getIconName(key, label)}.svg`)}
                  className="config__item--icon"
                  wrapper="span"
                />

                <div className="config__item--title">{label}</div>
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export class NumberOfPeople extends React.Component {
  isChecked(key, value) {
    if (Array.isArray(this.props.configuration[key])) {
      return this.props.configuration[key].includes(value)
    }
    // compare non-strictly because the number stored in value gets automatically transformed to string by input element
    // eslint-disable-next-line eqeqeq
    return this.props.configuration[key] == value
  }

  render() {
    const themBoxes = [
      '1 Person',
      '2 Personen',
      '3 Personen',
      '4 Personen',
      '5 Personen',
      '6 Personen oder mehr',
    ].map(number => {
      const id = `config-person-${number}`
      const actualNumber = parseInt(number, 10)

      const isChecked = this.isChecked('numberOfPeople', actualNumber)
      return (
        <div key={id} className={`config__item ${isChecked ? 'active' : ''}`}>
          <input
            onChange={e => {
              this.props.handleChange(e)
              this.props.nextStep()
            }}
            type="radio"
            name="numberOfPeople"
            id={id}
            className="dn"
            value={actualNumber}
            checked={isChecked}
          />
          <label htmlFor={id} className="config__item--content">
            <ReactSVG
              src={withPrefix(
                `/icons/${getIconName('numberOfPeople', actualNumber)}.svg`
              )}
              className="config__item--icon"
              wrapper="span"
            />

            <div className="config__item--title">{number}</div>
          </label>
        </div>
      )
    })
    return (
      <div className="config__step">
        <h2 className="config__step--title">
          Wieviele Personen leben in Ihrem Haushalt?
        </h2>
        <div className="config__items">{themBoxes}</div>
      </div>
    )
  }
}

const defaultPropTypes = {
  nextStep: PropTypes.func,
  handleSelectChange: PropTypes.func,
  configuration: configuratorFormPropType,
}

CurrentTypeOfHeating.propTypes = defaultPropTypes
DeclaredEnergyConsumption.propTypes = {
  ...defaultPropTypes,
  handleChange: PropTypes.func,
  displayPlaceholder: PropTypes.func,
}
HeatDistributionSystem.propTypes = {
  ...defaultPropTypes,
  handleMultiSelectChange: PropTypes.func,
}
HeatedArea.propTypes = defaultPropTypes
WaterHeating.propTypes = defaultPropTypes
NumberOfPeople.propTypes = { ...defaultPropTypes, handleChange: PropTypes.func }
