import React from 'react'
import { siteDataPropType } from 'lib/prop-types'
import HeizungscheckWizard from 'components/HeizungscheckWizard'
import Layout from 'components/Layout'
import SiteMetadata from 'lib/SiteMetadata'
import { Helmet } from 'react-helmet'

class Heizungscheck extends React.Component {
  constructor(props) {
    super(props)

    this.siteMetadata = props.data.site.siteMetadata
  }

  render() {
    return (
      <Layout>
        <Helmet title="Online Wärmepumpen-Check">
          <meta
            name="description"
            content="Der Heizungsmacher.ch Heizungscheck ➤ Einfach 6 Fragen beantworten & erfahren, ob eine Wärmepumpe auch für Ihr Haus geeignet ist."
          />
        </Helmet>
        <div className="section bg__flex--grey">
          <HeizungscheckWizard />
        </div>
      </Layout>
    )
  }
}

Heizungscheck.propTypes = {
  data: siteDataPropType,
}

const HeizungscheckSite = props => (
  <SiteMetadata render={data => <Heizungscheck data={data} {...props} />} />
)

export default HeizungscheckSite
