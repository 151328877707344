import React from 'react'
import PropTypes from 'prop-types'
import Button from '../Button'
import ContactFormFields from '../ContactFormFields'
import PNew from 'components/PNew'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamation,
  faPencilAlt,
  faEnvelopeOpen,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { contactPropType, configuratorFormPropType } from 'lib/prop-types'
import Link from '../Link'

const ConfiguratorResult = props => {
  const { configuration, isSubmitting, isLoading } = props
  return (
    <div className="wrap__box--pad-l">
      <div className="results__overview">
        <div
          onClick={() => props.goToStep(1)}
          className="results__overview--mobile"
        >
          <FontAwesomeIcon icon={faPencilAlt} className="link__icon--left" />
          Angaben bearbeiten
        </div>
        <div
          onClick={() => props.goToStep(1)}
          className="results__overview--item link"
        >
          <h4 className="fw--regular margin--0">Bisherige Heizung</h4>
          <p className="fw--bold mb0">
            {configuration.currentTypeOfHeating || 'Keine Angabe'}
          </p>
        </div>
        <div
          onClick={() => props.goToStep(2)}
          className="results__overview--item link"
        >
          <h4 className="fw--regular margin--0">Verbrauch</h4>
          <p className="fw--bold mb0">
            {configuration.declaredEnergyConsumption || 'Keine Angabe'}
          </p>
        </div>
        <div
          onClick={() => props.goToStep(3)}
          className="results__overview--item link"
        >
          <h4 className="fw--regular margin--0">Heizverteilung</h4>
          <p className="fw--bold mb0">
            {configuration.heatDistributionSystem || 'Keine Angabe'}
          </p>
        </div>
        <div
          onClick={() => props.goToStep(4)}
          className="results__overview--item link"
        >
          <h4 className="fw--regular margin--0">Beheizte Fläche</h4>
          <p className="fw--bold mb0">
            {configuration.heatedArea || 'Keine Angabe'}
          </p>
        </div>
        <div
          onClick={() => props.goToStep(5)}
          className="results__overview--item link"
        >
          <h4 className="fw--regular margin--0">Warmwasser</h4>
          <p className="fw--bold mb0">
            {configuration.waterHeating || 'Keine Angabe'}
          </p>
        </div>
        <div
          onClick={() => props.goToStep(6)}
          className="results__overview--item link"
        >
          <h4 className="fw--regular margin--0">Anzahl Personen</h4>
          <p className="fw--bold mb0">
            {configuration.numberOfPeople || 'Keine Angabe'}
          </p>
        </div>
      </div>
      <div className="columns__box">
        <div className="column--half">
          <h2 className="bb__3--dark margin--t">Ihr Ergebnis</h2>
          <PNew
            requiredHeatingPower={props.requiredHeatingPower}
            specificPower={props.specificPower}
            missingData={Object.values(configuration).some(
              value => value === ''
            )}
            heatDistribution={configuration.heatDistributionSystem}
            isLoading={isLoading}
          />
          {isLoading ? (
            <div className="fw--bold">
              Lädt
              <FontAwesomeIcon
                icon={faSpinner}
                className="fa-spin margin--l05"
              />
            </div>
          ) : null}
        </div>
        <div className="column--half">
          <h2 className="bb__3--dark margin--t">Kontakt aufnehmen</h2>
          <ContactFormFields
            handleChange={e => {
              props.onChange(e.target.name, e.target.value)
            }}
            state={props.contact}
          />
          <div className="confirm__actions">
            <div className="confirm__submit">
              <Button className="btn btn--primary" disabled={isSubmitting}>
                {isSubmitting ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="fa-spin margin--r05"
                  />
                ) : null}
                Anfrage absenden
              </Button>
            </div>
          </div>
          {props.error ? (
            <div className="callout callout--error mv3">
              <span className="callout__icon">
                <FontAwesomeIcon icon={faExclamation} />
              </span>
              <span className="callout__text">
                Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.
              </span>
            </div>
          ) : props.success ? (
            <>
              <div className="callout callout--success mv3">
                <span className="callout__icon">
                  <FontAwesomeIcon icon={faEnvelopeOpen} />
                </span>
                <span className="callout__text">
                  Danke! Wir haben Ihre Nachricht erhalten und machen uns nun an
                  die Arbeit. Sie hören in Kürze von uns.
                </span>
              </div>
              <div className="contact__success--cta">
                <Link to="/photovoltaik-check" className="btn btn--primary">
                  Photovoltaik Check
                </Link>
                <div>
                  Bitte füllen Sie doch auch den Solarcheck aus, um uns mehr
                  Infos zu geben.
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

ConfiguratorResult.propTypes = {
  requiredHeatingPower: PropTypes.number,
  specificPower: PropTypes.number,
  onChange: PropTypes.func,
  contact: contactPropType,
  configuration: configuratorFormPropType,
  success: PropTypes.bool,
  error: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  goToStep: PropTypes.func,
}

export default ConfiguratorResult
