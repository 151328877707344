import React from 'react'
import PropTypes from 'prop-types'
import Popper from 'popper.js'
import Link from 'components/Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faExclamation,
  faInfoCircle,
  faBell,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'

const maxRequiredHeatingPower = 25
const maxSpecificPower = 100

const specificPowerInfo = value => {
  if (value <= 20) {
    return {
      icon: faCheck,
      color: 'success',
      title: 'Sehr gute Eignung',
      text: 'Die benötigte Heizleistung pro m² entspricht den Minergie-P Vorschriften und ist ausserordentlich gut für eine Wärmepumpe geeignet.',
    }
  }

  if (value <= 30) {
    return {
      icon: faCheck,
      color: 'success',
      title: 'Sehr gute Eignung',
      text: 'Die benötigte Heizleistung pro m² entspricht den Minergie Vorschriften und ist sehr gut für eine Wärmepumpe geeignet.',
    }
  }

  if (value <= 40) {
    return {
      icon: faCheck,
      color: 'success',
      title: 'Sehr gute Eignung',
      text: 'Die benötigte Heizleistung pro m² entspricht den heutigen Neubau Vorschriften und ist sehr gut für eine Wärmepumpe geeignet.',
    }
  }

  if (value <= 60) {
    return {
      icon: faCheck,
      color: 'success',
      title: 'Sehr gute Eignung',
      text: 'Die benötigte Heizleistung pro m² entspricht einem gut gedämmten bestehenden Haus und ist sehr gut für eine Wärmepumpe geeignet.',
    }
  }

  if (value <= 80) {
    return {
      icon: faCheck,
      color: 'success',
      title: 'Gute Eignung',
      text: 'Die benötigte Heizleistung pro m² entspricht einem teilweise gedämmten bestehenden Haus und ist für eine Wärmepumpe geeignet.',
    }
  }

  if (value <= 100) {
    return {
      icon: faCheck,
      color: 'success',
      title: 'Voraussichtlich geeignet',
      text: '',
    }
  }

  return {
    icon: faExclamation,
    color: 'info',
    title: 'Erhöhter Leistungsbedarf - Bitte Kontakt aufnehmen',
    text: 'Die benötigte Heizleistung pro m² ist wahrscheinlich zu hoch für eine Wärmepumpe anhand der eingegebenen Werte. Sprechen Sie sich mit unseren Expert:Innen ab.',
  }
}

class PNew extends React.Component {
  constructor(props) {
    super(props)

    this.requiredHeatingPowerIndicator = {
      text: React.createRef(),
      pin: React.createRef(),
    }
    this.specificPowerIndicator = {
      text: React.createRef(),
      pin: React.createRef(),
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.requiredHeatingPower === this.props.requiredHeatingPower) {
      return
    }

    if (this.poppers) {
      this.poppers.forEach(popper => popper?.destroy())
    }
    this.createPoppers()
  }

  componentDidMount() {
    this.createPoppers()
  }

  createPoppers() {
    this.poppers = [
      this.requiredHeatingPowerIndicator,
      this.specificPowerIndicator,
    ].map(indicator => {
      if (indicator.pin.current && indicator.text.current) {
        const popper = new Popper(
          indicator.pin.current,
          indicator.text.current,
          {
            placement: 'top',
            modifiers: {
              flip: {
                enabled: false,
              },
              preventOverflow: {
                boundariesElement: indicator.pin.current,
              },
            },
          }
        )
        setTimeout(() => popper.update(), 250)
        return popper
      }
    })
  }

  render() {
    const { requiredHeatingPower, specificPower, isLoading } = this.props
    const info = specificPowerInfo(specificPower)

    return (
      <div className="pnew__box relative">
        <p>
          Anhand Ihrer Daten aus dem online Heizungscheck wurde die benötigte
          Heizleistung errechnet. Diese kann je nach Standort variieren. Wir
          erstellen gerne eine standortspezifische Berechnung für Sie.
        </p>

        {isLoading ? (
          <div className="loading-state__overlay">
            <div className="loading-state__inner">
              Lädt
              <FontAwesomeIcon
                icon={faSpinner}
                className="fa-spin margin--l05"
              />
            </div>
          </div>
        ) : null}

        {typeof requiredHeatingPower !== 'undefined' ? (
          <>
            <div
              className={`callout callout--${info.color} fw--bold margin--b05`}
            >
              <span className="callout__icon">
                <FontAwesomeIcon icon={info.icon} />
              </span>
              <span className="callout__text">{info.title}</span>
            </div>
            {info.text && (
              <p
                className="pnew__copy"
                dangerouslySetInnerHTML={{ __html: info.text }}
              ></p>
            )}
          </>
        ) : null}

        {this.props.missingData ? (
          <p className="pnew__copy">
            <strong>
              <FontAwesomeIcon
                icon={faBell}
                className="link__icon--left color--yellow"
              />
              Hinweis:&nbsp;
            </strong>
            Es fehlen Angaben, das ist kein Problem. Für die Berechnung wurden
            Einfamilienhaus-Standardwerte verwendet. Nehmen Sie mit uns{' '}
            <Link to="/kontakt" className="link link--primary">
              Kontakt
            </Link>{' '}
            auf für die offenen Punkte.
          </p>
        ) : null}

        {this.props.heatDistribution ? (
          <p className="pnew__copy">
            <strong>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="link__icon--left color--yellow"
              />
              Info:&nbsp;
            </strong>
            {this.props.heatDistribution === 'Radiatoren' ? (
              <span>
                Radiatoren benötigen eine höhere Vorlauftemperatur, moderne
                Wärmepumpen erreichen &gt; 60°C. Meist reichen tiefere
                Vorlauftemperaturen jedoch aus.
              </span>
            ) : this.props.heatDistribution === 'Bodenheizung' ? (
              <span>
                Fussbodenheizungen eignen sich dank den tiefen
                Vorlauftemperaturen sehr gut für Wärmepumpen.
              </span>
            ) : this.props.heatDistribution ===
              'Bodenheizung und Radiatoren' ? (
              <span>
                Mehrere separat gesteuerte Heizkreise sind für eine moderne
                Wärmepumpensteuerung kein Problem.
              </span>
            ) : (
              <span>
                Für die Wärmepumpe wird ein wassergeführtes Verteilsystem
                (Radiatoren oder Fussbodenheizung) benötigt. Gemäss Ihren
                Angaben haben Sie keines davon.
              </span>
            )}
          </p>
        ) : null}

        <h3 className="margin--t">
          Benötigte Heizleistung bei einer Aussentemperatur von -7°C
        </h3>
        <div className="pnew__wrapper">
          {typeof requiredHeatingPower !== 'undefined' ? (
            <div className="pnew__state">
              <div
                className="pnew__state--result"
                ref={this.requiredHeatingPowerIndicator.text}
              >
                Ihre benötigte Leistung:
                <span className="fw--bold">
                  {requiredHeatingPower <= maxRequiredHeatingPower
                    ? ` ${requiredHeatingPower}`
                    : `> ${maxRequiredHeatingPower}`}{' '}
                  kW
                </span>
              </div>
              <div
                className="pnew__state--pin"
                ref={this.requiredHeatingPowerIndicator.pin}
                style={{
                  marginLeft: `${
                    (Math.min(requiredHeatingPower, maxRequiredHeatingPower) /
                      maxRequiredHeatingPower) *
                    100
                  }%`,
                }}
              ></div>
            </div>
          ) : (
            <p className="text--small">
              Anhand Ihrer Daten aus dem online Heizungscheck wird hier Ihre
              benötigte Heizleistung errechnet.
            </p>
          )}
          <div className="stacked-bar">
            <div
              style={{ width: '12%' }}
              className="stacked-bar__segment stacked-bar__segment--white"
            ></div>
            <div
              style={{ width: '12%' }}
              className="stacked-bar__segment stacked-bar__segment--darkblue"
            ></div>
            <div
              style={{ width: '16%' }}
              className="stacked-bar__segment stacked-bar__segment--lightblue"
            ></div>
            <div
              style={{ width: '8%' }}
              className="stacked-bar__segment stacked-bar__segment--darkgreen"
            ></div>
            <div
              style={{ width: '12%' }}
              className="stacked-bar__segment stacked-bar__segment--lightgreen"
            ></div>
            <div
              style={{ width: '40%' }}
              className="stacked-bar__segment stacked-bar__segment--beige"
            ></div>
          </div>
          <div className="stacked-bar">
            {[0, 5, 10, 15, 20, 25].map(step => (
              <span
                key={`specific-power__${step}`}
                className="stacked-bar__nr"
                style={{ position: 'absolute', left: `${0.98 * step * 4}%` }}
              >
                {step}
              </span>
            ))}
          </div>
        </div>

        <h3 className="margin--t">
          Benötigte Heizleistung pro m² beheizte Fläche
        </h3>
        <div>
          <div className="pnew__state">
            <div
              className="pnew__state--result"
              ref={this.specificPowerIndicator.text}
            >
              Spezifische Leistung:
              <span className="fw--bold">
                {specificPower <= maxSpecificPower
                  ? ` ${specificPower}`
                  : `> ${maxSpecificPower}`}{' '}
                W/m²
              </span>
            </div>
            <div
              className="pnew__state--pin"
              ref={this.specificPowerIndicator.pin}
              style={{
                marginLeft: `${
                  (Math.min(specificPower, maxSpecificPower) /
                    maxSpecificPower) *
                  100
                }%`,
              }}
            ></div>
          </div>
          <div className="stacked-bar">
            <div
              style={{ width: '20%' }}
              className="stacked-bar__segment stacked-bar__segment--darkblue"
            ></div>
            <div
              style={{ width: '10%' }}
              className="stacked-bar__segment stacked-bar__segment--lightblue"
            ></div>
            <div
              style={{ width: '10%' }}
              className="stacked-bar__segment stacked-bar__segment--darkgreen"
            ></div>
            <div
              style={{ width: '20%' }}
              className="stacked-bar__segment stacked-bar__segment--lightgreen"
            ></div>
            <div
              style={{ width: '40%' }}
              className="stacked-bar__segment stacked-bar__segment--beige"
            ></div>
          </div>
          <div className="stacked-bar">
            {[0, 20, 40, 60, 80, 100].map(step => (
              <span
                key={`specific-power__${step}`}
                className="stacked-bar__nr"
                style={{ position: 'absolute', left: `${0.98 * step}%` }}
              >
                {step}
              </span>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
export default PNew

PNew.propTypes = {
  requiredHeatingPower: PropTypes.number,
  specificPower: PropTypes.number,
  missingData: PropTypes.bool,
  heatDistribution: PropTypes.string,
  isLoading: PropTypes.bool,
}
