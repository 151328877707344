// remove all characters that are not part of the icon file name: everything in parentheses and digits in the beginning (because "123 Liter Heizöl" still gets the icon "Liter Heizöl")
const toRemove = / \(.*\)|^\d+ |^\d+$/g
// replace all slashes and spaces with a dash
const toDashes = /\/|\s/g

const getIconName = (key, value) => {
  if (key === 'numberOfPeople') {
    return value + '-persons'
  } else {
    return value.replace(toRemove, '').replace(toDashes, '-').toLowerCase()
  }
}

export default getIconName
