const powerPerPerson = 0.2
const fullLoadHours = 2000
const blockedTime = 2
const waterHeatingAmountPerPerson = 1000

const waterFactors = {
  electricWaterHeater: 0,
  radiator: 1,
  heatPumpWaterHeater: 0,
  solarThermalEnergy: 0.25,
  other: 0,
}

const efficiencies = {
  oil: 0.9,
  gas: 0.95,
  wood: 0.8,
  electricalStorage: 0.95,
  airOrWaterHeatPump: 3,
  geothermalProbeHeatPump: 4,
  other: 0,
}

const kwhRatios = {
  heatingOilInLiter: 10,
  heatingOilInKg: 11.86,
  gasInKWh: 1,
  gasInM3: 10.1,
  sterePieceWoodBeechOak: 2000,
  sterePieceWoodConiferousWood: 1540,
  sterePieceWoodMix: 1770,
  woodChipsInKgBeechOak: 5,
  woodChipsInKgConiferousWood: 5.4,
  woodChipsInM3BeechOak: 1150,
  woodChipsInM3ConiferousWood: 886,
  pelletsInKg: 5,
  pelletsInM3: 3250,
  kWhElectricity: 1,
}

const heizungscheckResult = data => {
  const heatedArea = Number(data.heatedArea) || 0
  const numberOfPeople = Number(data.numberOfPeople) || 0

  const currentConsumption = Number(data.declaredEnergyConsumption)
  const kwhRatio = kwhRatios[data.energyConsumptionUnits] || 1
  const efficiency = efficiencies[data.currentTypeOfHeating] || 0

  const previousWaterHeatingAmount = currentConsumption * kwhRatio * efficiency
  const waterFactor = waterFactors[data.waterHeating] || 0
  const waterHeatingAmount =
    previousWaterHeatingAmount -
    waterFactor * numberOfPeople * waterHeatingAmountPerPerson

  const pNewHeating = Number(
    ((waterHeatingAmount / fullLoadHours) * (24 / (24 - blockedTime))).toFixed(
      1
    )
  )

  let pNewWater = 0
  if (
    ['electricWaterHeater', 'radiator', 'solarThermalEnergy'].includes(
      data.waterHeating
    )
  ) {
    pNewWater = numberOfPeople * powerPerPerson
  }

  const requiredHeatingPower = Number((pNewHeating + pNewWater).toFixed(1))
  const specificPower =
    heatedArea === 0
      ? 0
      : Math.round((requiredHeatingPower / heatedArea) * 1000)

  return { requiredHeatingPower, specificPower }
}

export default heizungscheckResult
