import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { configuratorFormPropType } from 'lib/prop-types'
import Button from 'components/Button'
import Link from 'components/Link'
import createStore from 'lib/util/create-store'
import isEqual from 'lodash.isequal'
import {
  CurrentTypeOfHeating,
  DeclaredEnergyConsumption,
  HeatDistributionSystem,
  HeatedArea,
  WaterHeating,
  NumberOfPeople,
} from 'components/ConfiguratorForm'
import Result from 'components/ConfiguratorResult'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import isEmpty from 'lodash.isempty'
import StepWizard from 'react-step-wizard'
import cloneDeep from 'lodash.clonedeep'
import { withPrefix } from 'gatsby'
import getIconName from 'lib/get-icon-name'
import { ReactSVG } from 'react-svg'
import invokeNetlifyLambda from 'lib/invoke-netlify-lambda'
import Recaptcha, { generateCaptchaToken } from 'lib/recaptcha'
import pushDataLayer from 'lib/push-data-layer'
import heizungscheckResult from '../../lib/heizungscheck-result'

const configurationStore = createStore('configuration')
const contactStore = createStore('contact')

let wizardInstance

const defaultConfiguration = {
  currentTypeOfHeating: '',
  declaredEnergyConsumption: '',
  energyConsumptionUnits: '',
  heatDistributionSystem: '',
  heatedArea: '',
  waterHeating: '',
  numberOfPeople: '',
}

const defaultContact = {
  firstName: '',
  lastName: '',
  street: '',
  zipCode: '',
  city: '',
  email: '',
  phone: '',
  notes: '',
}

const labels = {
  currentTypeOfHeating: 'Bisherige Heizung',
  declaredEnergyConsumption: 'Verbrauch',
  heatDistributionSystem: 'Heizverteilung',
  heatedArea: 'Beheizte Fläche',
  waterHeating: 'Warmwasser',
  numberOfPeople: 'Anzahl Personen',
}

const consumptionStepNumber = 2

const valueIsConfigured = (configuration, key) => {
  return !isEqual(configuration[key], defaultConfiguration[key])
}

const displayValue = (key, configuration) => {
  const value = configuration[key]
  let result

  if (typeof value === 'undefined' || value === null) {
    result = ''
  } else if (typeof value === 'string') {
    result = value
  } else {
    result = value.label
  }
  if (
    key === 'declaredEnergyConsumption' &&
    configuration.energyConsumptionUnits
  ) {
    if (result) {
      result += ' ' + displayValue('energyConsumptionUnits', configuration)
    } else {
      return ''
    }
  }
  if (key === 'heatedArea' && configuration.heatedArea) {
    result += ' m²'
  }
  return result
}

const DataOverview = props => {
  const displayableData = Object.keys(props.configuration).filter(
    key => key !== 'energyConsumptionUnits'
  )
  const lastKeyToDisplay = [...displayableData]
    .reverse()
    .find(key => valueIsConfigured(props.configuration, key))
  const lastIndexToDisplay = Math.max(
    displayableData.indexOf(lastKeyToDisplay) + 1,
    props.currentStep
  )
  const data = displayableData.filter(
    (key, i) =>
      i + 1 < lastIndexToDisplay || valueIsConfigured(props.configuration, key)
  )
  if (
    props.currentStep === props.totalSteps &&
    !valueIsConfigured(props.configuration, 'numberOfPeople')
  ) {
    data.push('numberOfPeople')
  }
  if (
    props.currentStep === consumptionStepNumber &&
    props.displayPlaceholderForConsumption &&
    !data.includes('declaredEnergyConsumption')
  ) {
    data.push('declaredEnergyConsumption')
  }
  return (
    <div className="wizard-results__wrapper">
      <div className="wizard-results">
        {data.length > 0 ? (
          data.map(key => {
            const stepNr = Object.keys(labels).indexOf(key) + 1
            let value = displayValue(key, props.configuration)
            if (
              key === 'declaredEnergyConsumption' &&
              !value &&
              props.displayPlaceholderForConsumption
            ) {
              value = `0 ${displayValue(
                'energyConsumptionUnits',
                props.configuration
              )}`
            }
            const iconName = getIconName(key, value)
            return (
              <div
                key={`wizard-state-${key}`}
                className={props.animationClasses[key].join(' ')}
                onClick={() => {
                  props.scrollToConfigurator()
                  props.goToStep(stepNr)
                }}
              >
                <h4 className="wizard-result__title">{labels[key]}</h4>

                <div className="wizard-result__content">
                  <div key={`result-${key}-${value}`} className="wizard-result">
                    {value ? (
                      <Fragment>
                        {iconName ? (
                          <ReactSVG
                            src={withPrefix(`/icons/${iconName}.svg`)}
                            className="wizard-result__icon"
                            wrapper="span"
                          />
                        ) : null}
                        {value}
                      </Fragment>
                    ) : (
                      <div className="fw4 o-40">Keine Angabe</div>
                    )}
                  </div>
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    className="wizard-result__action"
                  />
                </div>
              </div>
            )
          })
        ) : (
          <div className="wizard__welcome">
            <h1 className="color--white">Willkommen im Heizungscheck</h1>
            <p>
              Mit dem online Heizungscheck können Sie mit 6 einfachen Fragen
              feststellen, ob eine Wärmepumpe für Ihr Einfamilienhaus geeignet
              ist.
            </p>
            <p>
              Falls Sie nicht alles ausfüllen können,{' '}
              <Link to="/kontakt" className="link">
                helfen Ihnen unsere Experten
              </Link>{' '}
              gerne.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

const WizardNavigation = props => {
  const isLastActualStep = props.currentStep === props.totalSteps
  const currentKey = Object.keys(labels)[props.currentStep - 1]
  return (
    <div className="wizard__nav">
      <div className="wizard__footer">
        {props.renderResetButton ? (
          <a
            className="reverse-link"
            onClick={e => {
              props.firstStep()
              props.onReset(e)
            }}
            href="#"
          >
            <FontAwesomeIcon icon={faUndoAlt} className="link__icon--left" />
            Zurücksetzen
          </a>
        ) : null}
        <div className="wizard__browse">
          <Button
            onClick={e => {
              e.preventDefault()
              props.scrollToConfigurator()
              props.nextStep()
              if (isLastActualStep) {
                props.calculateResult()
              }
            }}
            className="btn--primary ml3-ns"
          >
            {isLastActualStep
              ? 'Berechnen'
              : !valueIsConfigured(props.configuration, currentKey)
              ? 'Überspringen'
              : 'Weiter'}
          </Button>
          {props.currentStep > 1 ? (
            <Button
              onClick={e => {
                e.preventDefault()
                props.scrollToConfigurator()
                props.previousStep()
              }}
              className="btn--white"
            >
              Zurück
            </Button>
          ) : null}
        </div>
      </div>
      <div className="wizard__sidebar">
        <div className="wizard__progress">
          {Object.keys(labels).map((key, i) => {
            let className = 'wizard__progress--item'
            const linkedStep = i + 1 // the step indices are starting at 1
            if (props.currentStep === linkedStep) {
              className += ' doing'
            } else if (valueIsConfigured(props.configuration, key)) {
              className += ' done'
            }
            return (
              <div
                key={`navigation-item-${linkedStep}`}
                onClick={() => {
                  props.goToStep(linkedStep)
                }}
                className={className}
              >
                {linkedStep}
              </div>
            )
          })}
        </div>
        <DataOverview {...props} />
      </div>
    </div>
  )
}

class HeizungscheckWizard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      configuration:
        configurationStore.load() || cloneDeep(defaultConfiguration),
      contact: contactStore.load() || cloneDeep(defaultContact),
      animationClasses: {},
      showResult: false,
    }

    Object.keys(labels).forEach(
      key => (this.state.animationClasses[key] = ['wizard-results__item'])
    )
    this.configuratorRef = React.createRef()
  }

  fadeIn(key) {
    if (key !== 'energyConsumptionUnits') {
      const animationTimeOut = 1000 //in milliseconds
      const classes = this.state.animationClasses[key]
      classes.push('fade-in--left')
      this.setState({
        animationClasses: { ...this.state.animationClasses, [key]: classes },
      })
      setTimeout(() => {
        this.setState({
          animationClasses: {
            ...this.state.animationClasses,
            [key]: ['wizard-results__item'],
          },
        })
      }, animationTimeOut)
    }
  }

  scrollTo(ref) {
    window.scrollTo(0, ref.current.offsetTop)
  }

  handleSelectChange(key, data) {
    if (
      (Array.isArray(key) && !Array.isArray(data)) ||
      (!Array.isArray(key) && Array.isArray(data)) ||
      (Array.isArray(key) && key.length !== data.length)
    ) {
      console.error(
        'There has been an error trying to update the key(s) ',
        key,
        ' with the following data: ',
        data
      )
    }
    const keys = Array.isArray(key) ? key : [key]
    const values = Array.isArray(data) ? data : [data]

    const newConfiguration = { ...this.state.configuration }
    keys.forEach((key, i) => {
      this.fadeIn(key)
      const value = isEmpty(values[i]) ? '' : values[i]
      newConfiguration[key] = value
      pushDataLayer('event', 'step', {
        step: `${labels[key] || key} beendet`,
        'step-info': value.label,
      })
    })
    this.setState({ configuration: newConfiguration })
    configurationStore.save(newConfiguration)
  }

  handleChange(e) {
    const key = e.target.name
    const value = e.target.value
    this.fadeIn(key)
    pushDataLayer('event', 'step', {
      step: `${labels[key] || key} beendet`,
      'step-info': value,
    })
    const newConfiguration = {
      ...this.state.configuration,
      [key]: value,
    }
    this.setState({
      configuration: newConfiguration,
    })
    configurationStore.save(newConfiguration)
  }

  handleSubmit(e) {
    e.preventDefault()
    pushDataLayer('set', 'user_data', {
      email: this.state.contact.email,
      phone_number: this.state.contact.phone,
    })
    pushDataLayer('event', 'contact', { type: 'Heizungscheck' })
    const GCLID = e.target?.GCLID?.value
    generateCaptchaToken().then(token => {
      const data = {
        ...this.state.contact,
        ...this.extractConfigurationValues(),
        typeOfForm: 'Heizungscheck',
        GCLID,
        captchaToken: token,
      }
      this.setState({ success: false, error: false, isSubmitting: true })
      invokeNetlifyLambda
        .post('pardot-submit', JSON.stringify(data))
        .then(() => {
          this.setState({ success: true, error: false, isSubmitting: false })
        })
        .catch(e => {
          console.error(e)
          this.setState({ success: false, error: true, isSubmitting: false })
        })
    })
  }

  extractConfigurationValues() {
    const { configuration } = this.state
    return Object.keys(configuration).reduce((result, key) => {
      let value = ''
      if (
        typeof configuration[key] === 'string' ||
        Array.isArray(configuration[key])
      ) {
        value = configuration[key]
      } else {
        value = configuration[key].value
      }
      if (typeof value !== 'undefined' && value !== '') {
        result[key] = value
      }
      return result
    }, {})
  }

  calculateResult() {
    this.setState({ isLoading: true, showResult: true })

    const { requiredHeatingPower, specificPower } = heizungscheckResult(
      this.extractConfigurationValues()
    )

    setTimeout(() => {
      this.setState({
        isLoading: false,
        requiredHeatingPower,
        specificPower,
      })
    }, 1500)
  }

  reset() {
    this.setState({
      configuration: cloneDeep(defaultConfiguration),
    })
  }

  render() {
    return (
      <div className="section__inner section--padded-small">
        <form
          onSubmit={e => this.handleSubmit(e)}
          className="wrap__box--pad-0 bt__3--dark mb3"
        >
          <Recaptcha />
          <div className="config__wrap">
            {this.state.showResult ? (
              <Result
                requiredHeatingPower={this.state.requiredHeatingPower}
                specificPower={this.state.specificPower}
                onChange={(key, value) => {
                  const newContact = { ...this.state.contact, [key]: value }
                  contactStore.save(newContact)
                  this.setState({ contact: newContact })
                }}
                onSubmit={() => this.handleSubmit()}
                contact={this.state.contact}
                configuration={Object.keys(this.state.configuration).reduce(
                  (acc, key) => {
                    acc[key] = displayValue(key, this.state.configuration)
                    return acc
                  },
                  {}
                )}
                success={this.state.success}
                error={this.state.error}
                isLoading={this.state.isLoading}
                goToStep={number => {
                  this.setState({ showResult: false }, () => {
                    wizardInstance.goToStep(number)
                  })
                }}
                isSubmitting={this.state.isSubmitting}
              />
            ) : (
              <StepWizard
                className="config__content"
                ref={this.configuratorRef}
                instance={wizard => (wizardInstance = wizard)}
                nav={
                  <WizardNavigation
                    onReset={e => {
                      e.preventDefault()
                      this.reset()
                    }}
                    renderResetButton={
                      !isEqual(this.state.configuration, defaultConfiguration)
                    }
                    isLoading={this.state.isLoading}
                    configuration={this.state.configuration}
                    animationClasses={this.state.animationClasses}
                    scrollToConfigurator={() =>
                      this.scrollTo(this.configuratorRef)
                    }
                    displayPlaceholderForConsumption={
                      this.state.displayPlaceholderForConsumption
                    }
                    calculateResult={() => this.calculateResult()}
                  />
                }
              >
                <CurrentTypeOfHeating
                  configuration={this.state.configuration}
                  handleSelectChange={(name, data) =>
                    this.handleSelectChange(name, data)
                  }
                />
                <DeclaredEnergyConsumption
                  configuration={this.state.configuration}
                  handleChange={e => this.handleChange(e)}
                  handleSelectChange={(name, data) =>
                    this.handleSelectChange(name, data)
                  }
                  displayPlaceholder={({ display }) =>
                    this.setState({ displayPlaceholderForConsumption: display })
                  }
                />
                <HeatDistributionSystem
                  configuration={this.state.configuration}
                  handleSelectChange={(name, data) =>
                    this.handleSelectChange(name, data)
                  }
                />
                <HeatedArea
                  configuration={this.state.configuration}
                  handleSelectChange={(name, data) =>
                    this.handleSelectChange(name, data)
                  }
                />
                <WaterHeating
                  configuration={this.state.configuration}
                  handleSelectChange={(name, data) =>
                    this.handleSelectChange(name, data)
                  }
                />
                <NumberOfPeople
                  configuration={this.state.configuration}
                  handleChange={e => {
                    this.handleChange(e)
                    this.calculateResult()
                  }}
                />
              </StepWizard>
            )}
          </div>
        </form>
      </div>
    )
  }
}

DataOverview.propTypes = {
  configuration: configuratorFormPropType,
  animationClasses: PropTypes.object,
  goToStep: PropTypes.func,
  scrollToConfigurator: PropTypes.func,
  currentStep: PropTypes.number,
  displayPlaceholderForConsumption: PropTypes.bool,
  totalSteps: PropTypes.number,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
}

WizardNavigation.propTypes = {
  renderResetButton: PropTypes.bool,
  configuration: configuratorFormPropType,
  animationClasses: PropTypes.object,
  onReset: PropTypes.func,
  isLoading: PropTypes.bool,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  firstStep: PropTypes.func,
  goToStep: PropTypes.func,
  scrollToConfigurator: PropTypes.func,
  displayPlaceholderForConsumption: PropTypes.bool,
  calculateResult: PropTypes.func,
}

HeizungscheckWizard.propTypes = {
  scrollTo: PropTypes.func,
}

export default HeizungscheckWizard
